
.field-user-password.has-success input[type=text] {
    padding-right: 30px;
}
.field-user-password.has-success .input-group:after {
    font-family: "Ionicons";
    display: block;
    content: "\f383";
    width: 10px;
    height: 10px;
    color: green;
    position: absolute;
    right: 65px;
    top: 5px;
    z-index: 3;
    font-size: 18px;
}