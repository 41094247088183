a.fc-event {
    background-color: #fff;
    color: #1d2129;
    border-radius: 0 !important;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
}

a.fc-event:hover {
    z-index: 1000 !important;
}

.fc-day.fc-today {
    background: #fff !important;
}

/* запланирован, проведен */
a.fc-event.status1, a.fc-event.status3 {
    background-color: #fff;
    color: #1d2129 !important;
}

/* перегрузка препода */
a.fc-event.status1.fc-event.overload, a.fc-event.status3.fc-event.overload {
    background-color: rgba(255, 230, 230, 1);
}

/* свободное время */
a.fc-event.freetime {
    background-color: rgba(0, 255, 0, 0.2);
    border-top: none !important;
    border-right: none !important;
    border-bottom: none !important;
}

.teacher-legend {
    border-top: none !important;
    border-bottom: none !important;
    border-right: none !important;
    border-left-width: 10px !important;
    display: inline-block;
    height: 10px;
}

/* раскраска преподавателей */
a.fc-event.teacher, .teacher-legend.teacher {
    color: #1d2129;
    border: 1px solid;
    border-left: 6px solid;
}

.fc-timeGridWeek-view a.fc-event.teacher,
.fc-timeGridDay-view a.fc-event.teacher {
    /*width: 1.9% !important;*/
    /*left: 0% !important;*/
}

.fc-timeGridDay-view a {
    box-sizing: border-box;
}
.fc-timeGridDay-view a.fc-time-grid-event.fc-v-event.fc-event.fc-start.fc-end.partial_2 {
    width: 1.219512195122% !important;
}
.fc-timeGridDay-view a.fc-time-grid-event.fc-v-event.fc-event.fc-start.fc-end.partial_2.partial_part_2 {
    margin-left: 1.219512195122%;
}
.fc-timeGridDay-view a.fc-time-grid-event.fc-v-event.fc-event.fc-start.fc-end.partial_3 {
    width: 0.8130081300813% !important;
}
.fc-timeGridDay-view a.fc-time-grid-event.fc-v-event.fc-event.fc-start.fc-end.partial_3.partial_part_2 {
    margin-left: 0.8130081300813%;
}
.fc-timeGridDay-view a.fc-time-grid-event.fc-v-event.fc-event.fc-start.fc-end.partial_3.partial_part_3 {
    margin-left: 1.6260162601626%;
}
.fc-timeGridDay-view a.fc-time-grid-event.fc-v-event.fc-event.fc-start.fc-end.partial_4 {
    width: 0.60975609756098% !important;
}
.fc-timeGridDay-view a.fc-time-grid-event.fc-v-event.fc-event.fc-start.fc-end.partial_4.partial_part_2 {
    margin-left: 0.60975609756098%;
}
.fc-timeGridDay-view a.fc-time-grid-event.fc-v-event.fc-event.fc-start.fc-end.partial_4.partial_part_3 {
    margin-left: 1.219512195122%;
}
.fc-timeGridDay-view a.fc-time-grid-event.fc-v-event.fc-event.fc-start.fc-end.partial_4.partial_part_4 {
    margin-left: 1.8292682926829%;
}
.fc-timeGridDay-view a.fc-time-grid-event.fc-v-event.fc-event.fc-start.fc-end.partial_5 {
    width: 0.48780487804878% !important;
}
.fc-timeGridDay-view a.fc-time-grid-event.fc-v-event.fc-event.fc-start.fc-end.partial_5.partial_part_2 {
    margin-left: 0.48780487804878%;
}
.fc-timeGridDay-view a.fc-time-grid-event.fc-v-event.fc-event.fc-start.fc-end.partial_5.partial_part_3 {
    margin-left: 0.97560975609756%;
}
.fc-timeGridDay-view a.fc-time-grid-event.fc-v-event.fc-event.fc-start.fc-end.partial_5.partial_part_4 {
    margin-left: 1.4634146341463%;
}
.fc-timeGridDay-view a.fc-time-grid-event.fc-v-event.fc-event.fc-start.fc-end.partial_5.partial_part_5 {
    margin-left: 1.9512195121951%;
}

.fc-timeGridWeek-view a {
    box-sizing: border-box;
}

.fc-event.teacher {
    box-sizing: border-box !important;
}

.fc-agendaWeek-view.lineup a.fc-event.teacher2,
.fc-agendaDay-view.lineup a.fc-event.teacher2 {
    width: 1.9% !important;
    left: 0% !important;
}
.fc-agendaWeek-view.lineup a.fc-event.teacher90,
.fc-agendaDay-view.lineup a.fc-event.teacher90 {
    width: 1.9% !important;
    left: 2% !important;
}
.fc-agendaWeek-view.lineup a.fc-event.teacher94,
.fc-agendaDay-view.lineup a.fc-event.teacher94 {
    width: 1.9% !important;
    left: 4% !important;
}
.fc-agendaWeek-view.lineup a.fc-event.teacher108,
.fc-agendaDay-view.lineup a.fc-event.teacher108 {
    width: 1.9% !important;
    left: 6% !important;
}
.fc-agendaWeek-view.lineup a.fc-event.teacher95,
.fc-agendaDay-view.lineup a.fc-event.teacher95 {
    width: 1.9% !important;
    left: 8% !important;
}
.fc-agendaWeek-view.lineup a.fc-event.teacher96,
.fc-agendaDay-view.lineup a.fc-event.teacher96 {
    width: 1.9% !important;
    left: 10% !important;
}
.fc-agendaWeek-view.lineup a.fc-event.teacher17,
.fc-agendaDay-view.lineup a.fc-event.teacher17 {
    width: 1.9% !important;
    left: 12% !important;
}
.fc-agendaWeek-view.lineup a.fc-event.teacher97,
.fc-agendaDay-view.lineup a.fc-event.teacher97 {
    width: 1.9% !important;
    left: 14% !important;
}
.fc-agendaWeek-view.lineup a.fc-event.teacher118,
.fc-agendaDay-view.lineup a.fc-event.teacher118 {
    width: 1.9% !important;
    left: 16% !important;
}
.fc-agendaWeek-view.lineup a.fc-event.teacher112,
.fc-agendaDay-view.lineup a.fc-event.teacher112 {
    width: 1.9% !important;
    left: 18% !important;
}
.fc-agendaWeek-view.lineup a.fc-event.teacher92,
.fc-agendaDay-view.lineup a.fc-event.teacher92 {
    width: 1.9% !important;
    left: 20% !important;
}
.fc-agendaWeek-view.lineup a.fc-event.teacher119,
.fc-agendaDay-view.lineup a.fc-event.teacher119 {
    width: 1.9% !important;
    left: 22% !important;
}
.fc-agendaWeek-view.lineup a.fc-event.teacher88,
.fc-agendaDay-view.lineup a.fc-event.teacher88 {
    width: 1.9% !important;
    left: 24% !important;
}
.fc-agendaWeek-view.lineup a.fc-event.teacher116,
.fc-agendaDay-view.lineup a.fc-event.teacher116 {
    width: 1.9% !important;
    left: 26% !important;
}
.fc-agendaWeek-view.lineup a.fc-event.teacher106,
.fc-agendaDay-view.lineup a.fc-event.teacher106 {
    width: 1.9% !important;
    left: 28% !important;
}
.fc-agendaWeek-view.lineup a.fc-event.teacher8,
.fc-agendaDay-view.lineup a.fc-event.teacher8 {
    width: 1.9% !important;
    left: 30% !important;
}
.fc-agendaWeek-view.lineup a.fc-event.teacher3,
.fc-agendaDay-view.lineup a.fc-event.teacher3 {
    width: 1.9% !important;
    left: 32% !important;
}
.fc-agendaWeek-view.lineup a.fc-event.teacher126,
.fc-agendaDay-view.lineup a.fc-event.teacher126 {
    width: 1.9% !important;
    left: 34% !important;
}
.fc-agendaWeek-view.lineup a.fc-event.teacher83,
.fc-agendaDay-view.lineup a.fc-event.teacher83 {
    width: 1.9% !important;
    left: 36% !important;
}
.fc-agendaWeek-view.lineup a.fc-event.teacher54,
.fc-agendaDay-view.lineup a.fc-event.teacher54 {
    width: 1.9% !important;
    left: 38% !important;
}
.fc-agendaWeek-view.lineup a.fc-event.teacher109,
.fc-agendaDay-view.lineup a.fc-event.teacher109 {
    width: 1.9% !important;
    left: 40% !important;
}
.fc-agendaWeek-view.lineup a.fc-event.teacher81,
.fc-agendaDay-view.lineup a.fc-event.teacher81 {
    width: 1.9% !important;
    left: 42% !important;
}
.fc-agendaWeek-view.lineup a.fc-event.teacher29,
.fc-agendaDay-view.lineup a.fc-event.teacher29 {
    width: 1.9% !important;
    left: 44% !important;
}
.fc-agendaWeek-view.lineup a.fc-event.teacher124,
.fc-agendaDay-view.lineup a.fc-event.teacher124 {
    width: 1.9% !important;
    left: 46% !important;
}
.fc-agendaWeek-view.lineup a.fc-event.teacher18,
.fc-agendaDay-view.lineup a.fc-event.teacher18 {
    width: 1.9% !important;
    left: 48% !important;
}
.fc-agendaWeek-view.lineup a.fc-event.teacher52,
.fc-agendaDay-view.lineup a.fc-event.teacher52 {
    width: 1.9% !important;
    left: 50% !important;
}
.fc-agendaWeek-view.lineup a.fc-event.teacher102,
.fc-agendaDay-view.lineup a.fc-event.teacher102 {
    width: 1.9% !important;
    left: 52% !important;
}
.fc-agendaWeek-view.lineup a.fc-event.teacher125,
.fc-agendaDay-view.lineup a.fc-event.teacher125 {
    width: 1.9% !important;
    left: 54% !important;
}
.fc-agendaWeek-view.lineup a.fc-event.teacher72,
.fc-agendaDay-view.lineup a.fc-event.teacher72 {
    width: 1.9% !important;
    left: 56% !important;
}
.fc-agendaWeek-view.lineup a.fc-event.teacher77,
.fc-agendaDay-view.lineup a.fc-event.teacher77 {
    width: 1.9% !important;
    left: 58% !important;
}
.fc-agendaWeek-view.lineup a.fc-event.teacher105,
.fc-agendaDay-view.lineup a.fc-event.teacher105 {
    width: 1.9% !important;
    left: 60% !important;
}
.fc-agendaWeek-view.lineup a.fc-event.teacher85,
.fc-agendaDay-view.lineup a.fc-event.teacher85 {
    width: 1.9% !important;
    left: 62% !important;
}
.fc-agendaWeek-view.lineup a.fc-event.teacher86,
.fc-agendaDay-view.lineup a.fc-event.teacher86 {
    width: 1.9% !important;
    left: 64% !important;
}
.fc-agendaWeek-view.lineup a.fc-event.teacher127,
.fc-agendaDay-view.lineup a.fc-event.teacher127 {
    width: 1.9% !important;
    left: 66% !important;
}
.fc-agendaWeek-view.lineup a.fc-event.teacher115,
.fc-agendaDay-view.lineup a.fc-event.teacher115 {
    width: 1.9% !important;
    left: 68% !important;
}
.fc-agendaWeek-view.lineup a.fc-event.teacher62,
.fc-agendaDay-view.lineup a.fc-event.teacher62 {
    width: 1.9% !important;
    left: 70% !important;
}
.fc-agendaWeek-view.lineup a.fc-event.teacher28,
.fc-agendaDay-view.lineup a.fc-event.teacher28 {
    width: 1.9% !important;
    left: 72% !important;
}
.fc-agendaWeek-view.lineup a.fc-event.teacher99,
.fc-agendaDay-view.lineup a.fc-event.teacher99 {
    width: 1.9% !important;
    left: 74% !important;
}
.fc-agendaWeek-view.lineup a.fc-event.teacher114,
.fc-agendaDay-view.lineup a.fc-event.teacher114 {
    width: 1.9% !important;
    left: 76% !important;
}
.fc-agendaWeek-view.lineup a.fc-event.teacher49,
.fc-agendaDay-view.lineup a.fc-event.teacher49 {
    width: 1.9% !important;
    left: 78% !important;
}
.fc-agendaWeek-view.lineup a.fc-event.teacher74,
.fc-agendaDay-view.lineup a.fc-event.teacher74 {
    width: 1.9% !important;
    left: 80% !important;
}
.fc-agendaWeek-view.lineup a.fc-event.teacher101,
.fc-agendaDay-view.lineup a.fc-event.teacher101 {
    width: 1.9% !important;
    left: 82% !important;
}
.fc-agendaWeek-view.lineup a.fc-event.teacher64,
.fc-agendaDay-view.lineup a.fc-event.teacher64 {
    width: 1.9% !important;
    left: 84% !important;
}
.fc-agendaWeek-view.lineup a.fc-event.teacher56,
.fc-agendaDay-view.lineup a.fc-event.teacher56 {
    width: 1.9% !important;
    left: 86% !important;
}
.fc-agendaWeek-view.lineup a.fc-event.teacher75,
.fc-agendaDay-view.lineup a.fc-event.teacher75 {
    width: 1.9% !important;
    left: 88% !important;
}
.fc-agendaWeek-view.lineup a.fc-event.teacher98,
.fc-agendaDay-view.lineup a.fc-event.teacher98 {
    width: 1.9% !important;
    left: 90% !important;
}
.fc-agendaWeek-view.lineup a.fc-event.teacher107,
.fc-agendaDay-view.lineup a.fc-event.teacher107 {
    width: 1.9% !important;
    left: 92% !important;
}
.fc-agendaWeek-view.lineup a.fc-event.teacher104,
.fc-agendaDay-view.lineup a.fc-event.teacher104 {
    width: 1.9% !important;
    left: 94% !important;
}
.fc-agendaWeek-view.lineup a.fc-event.teacher111,
.fc-agendaDay-view.lineup a.fc-event.teacher111 {
    width: 1.9% !important;
    left: 96% !important;
}
.fc-agendaWeek-view.lineup a.fc-event.teacher122,
.fc-agendaDay-view.lineup a.fc-event.teacher122 {
    width: 1.9% !important;
    left: 98% !important;
}

.busytime {
    transition: width .5s;
}

.fc-timeGridDay-view .busytime:hover {
    width : 15% !important;
    z-index: 1000 !important;
}

.fc-timeGridWeek-view .busytime:hover {
    width : 100% !important;
    z-index: 1000 !important;
}