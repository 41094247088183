.svg-notes--feed-note-dims {
    width: 16px;
    height: 19px;
}

.feed-note__icon-border {
    border: 1px solid #979797;
    border-radius: 36px;
    fill: #979797;
    box-sizing: border-box;
    height: 36px;
    width: 36px;
    position: relative;
}