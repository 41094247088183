.btn-hour-row {
    padding-left: 30px;
}
.btn-day-hour {
    padding: 5px 9px;
}
input.btn-select-day {
    position: absolute;
    left: 0;
    top: 4px;
}
@media (max-width: 360px) {
    input.btn-select-day {
        left: 5px;
    }
}
.btn-select-all {
    cursor: pointer;
    color: #226895;
    user-select: none;
}
.btn-select-all p:first-letter {
    text-transform: uppercase;
}
.btn-select-all span
{
    background: red;
}
.btn-select-all.active .btn-select-all__inactive {
    display: none;
}
.btn-select-all:not(.active) .btn-select-all__active {
    display: none;
}
.teacher-working-hour-specify {
    padding-left: 30px;
}