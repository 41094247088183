.task-note-wrapper {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    min-height: min-content;
    margin-top: 10px;
    box-sizing: border-box;
    font-size: 15px;
}

.task-note-wrapper:first-of-type {
    margin-top: 0 !important;
}

.task-note-fixer {
    width: 100%;
}

.task-note {
    flex-wrap: wrap;
    background: #fff;
    display: flex;
    position: relative;
    color: #313942;
    border: 1px solid #e9e9e9;
    border-radius: 3px;
    padding: 10px;
    box-sizing: border-box;
    width: 100%;
    transition: border-color .5s;
}

.task-note-in-process {
    border-color: rgba(236, 178, 34, 0.4);
}

.task-note-in-process:hover {
    border-color: rgb(236, 178, 34);
}

.task-note-overdue {
    border-color : #e8397f69;
}

.task-note-overdue:hover {
    border-color : #e8397f;
}

.task-note p {
    line-height: 20px;
    white-space: break-spaces;
    word-wrap: break-word;
    word-break: break-word;
}

.task-note__icon {
    width: 36px;
    box-sizing: border-box;
    margin-right: 10px;
    margin-top: 2px;
    display: flex;
    flex-shrink: 0;
}

.task-note__icon-inner {
    /*border: 1px solid #979797;*/
    border-radius: 36px;
    /*fill: #979797;*/
    box-sizing: border-box;
    height: 36px;
    width: 36px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}

.task-note__content {
    display: flex;
    flex-direction: column;
    color: #313942;
    flex-grow: 1;
    width: calc(100% - 46px);
    max-width: calc(100% - 46px);
}

.task-note__header {
    display: flex;
    font-size: 13px;
    color: #979797;
    line-height: 20px;
    overflow: hidden;
}

.task-note__header-inner {
    display: flex;
    justify-content: space-between;
    white-space: nowrap;
    position: relative;
    align-items: center;
    width: 100%;
    order: 1;
    flex: 0 1 auto;
    overflow: hidden;
    min-width: 0;
}

.task-note__header-inner-nowrap {
    display: flex;
    flex-shrink: 0;
    white-space: inherit;
}

.task-note__date {
    padding-right: 0.25em;
    white-space: nowrap;
    flex-shrink: 0;
}

.task-note__body {
    flex: 1 1 auto;
    overflow: hidden;
    padding-right: 5px;
}

.task-note__task-text {
    color: #313942;
    margin-top: -1px;
    width: 100%;
}

.task-note__body-text {
    margin : 0 !important;
}

.card-task__result-wrapper {
    margin-top: 7px;
}

.card-task__result-wrapper__inner {
    display: flex;
}

.card-task__result-wrapper__inner__textarea {
    width: 100%;
    padding: 8px 10px;
    height: 33px !important;
    box-sizing: border-box;
    resize: none;
    min-height: 33px;
    max-height: 180px;
    line-height: 20px;
    overflow: hidden;
    overflow-wrap: break-word;
}

.card-task__button {
    color: #fff;
    border-width: 1px;
    border-color: #4c8bf7;
    background: #4c8bf7;
    z-index: 0;
    flex-shrink: 0;
    margin-left: 10px;
    padding: 0 20px;
    text-transform: none;
    height: 38px;
    cursor: pointer;
    position: relative;
    display: inline-block;
    font-size: 14px;
    font-weight: 600;
    line-height: 14px;
    white-space: nowrap;
    vertical-align: middle;
    text-decoration: none;
    border-radius: 3px;
    outline: 0;
    user-select: none;
}

.button-input-inner {
    padding: 0;
    display: inline-flex;
    align-items: center;
    position: relative;
}

.card-task__clone {
    display: flex;
    align-items: center;
    margin-top: 10px;
    white-space: nowrap;
    font-size: 13px;
}

.card-task__clone__dates {
    display: flex;
    align-items: baseline;
    margin-top: -1px;
}

.card-task__clone__dates__preset {
    display: flex;
    cursor: pointer;
    border-radius: 3px;
}

.card-task__buttons {
    flex: 1 0 auto;
    display: flex;
    justify-content: flex-end;
}