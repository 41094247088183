dl.row dt {
    font-weight: 400
}
.customer-name {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 210px;
    display: inline-block;
    line-height: 10px;
}
