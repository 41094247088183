.search-input {
    width: 95%;
    height: 30px;
    font-size: 14px;
    line-height: 19px;
    user-select: text;
    outline: 0;
    border: 0;
    text-align: center;
}

.icon-search {
    z-index: 103;
    position: absolute;
    top: 21px;
    left: 21px;
    font-size: 15px;
    cursor: pointer;
}

.icon-filter {
    z-index: 103;
    position: absolute;
    top: 19px;
    font-size: 13px;
    cursor: pointer;
    left: 15px;
}

.icon-send {
    z-index: 103;
    position: absolute;
    top: 21px;
    font-size: 11px;
    cursor: pointer;
    left: 20px;
}

.icon-alert {
    z-index: 103;
    position: absolute;
    top: 21px;
    font-size: 13px;
    cursor: pointer;
    right: 20px;
}

/* FEED */
.inbox_message_list {
    width: 100%;
    flex-grow: 1;
    left: 0;
    right: 0;
    position: relative;
    height: 100%;
}

.inbox_message_list_container {
    position: relative;
    overflow: hidden;
    width: 100%;
    will-change: transform;
    /*min-height: 500px;*/
    /*min-height: 200px;*/
    max-height: 549.86px;
    overflow-y: auto;
}

.inbox_message_list_wrapper {
    /*position: absolute; */
    position: relative;
    top: 0px;
    left: 0px;
    height: 100%;
    width: 100%;
    overflow: visible;
}

.inbox_message_list_container::-webkit-scrollbar {
    width: 8px !important;
}

.inbox_message_list_container::-webkit-scrollbar-track {
    background: transparent !important;
}

.inbox_message_list_container::-webkit-scrollbar-thumb {
    background-clip: content-box;
    border: 2px solid transparent !important;
}

.feed-message-item {
    min-height: 67.56px;
    box-sizing: border-box;
    position: relative;
    width: 100%;
    border-bottom: 1px solid #efefef;
    user-select: none;
    cursor: pointer;
}

.notification-inner__navigate-link {
    display: flex;
    width: 100%;
    min-height: inherit;
    text-decoration: none!important;
    cursor: pointer;
}

.notification-chat__non-select {
    position: absolute;
    top: 0;
    height: 100%;
    width: 70px;
    user-select: none;
    margin: 0;
    padding: 0;
    border: 0;
}

.notification-chat__container-img {
    width: 40px;
    position: absolute;
    height: 40px;
    top: 13px!important;
    margin-top: 0!important;
    margin-left: 10px!important;
}

.notification-inner__avatar {
    /*box-shadow: 0 0 0 1.5px #fff, 0 0 0 4px #007ce4;*/
    box-shadow: 0 0 0 2px #d5d5d5;
    user-select: none;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    pointer-events: none;
    overflow: hidden;
    background-size: cover;
    background-position: center;
    object-fit: cover;
}

.notification-chat__container-icon {
    right: -8px;
    bottom: -5px;
    position: absolute;
    width: 20px;
    box-sizing: border-box;
    height: 20px;
    fill: #6d7379;
    border-radius: 50%;
}

.notification-inner__container_text  {
    width: calc(100% - 70px);
    margin-left: 63px;
    position: relative;
    padding: 10px 0 13px 0;
    align-self: flex-start;
}

.notification-inner__info_message {
    display: inline-flex;
    width: 100%;
    margin: 0;
    padding: 0;
    border: 0;
    cursor: pointer;
}

.notification-inner__title_message {
    display: inline-flex;
    width: auto;
    margin-bottom: 0;
}

.notification-inner__container_text h2, .notification-mail__container_text h2 {
    font-size: 15px;
    font-weight: 700;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    flex: 1 0 0px;
    padding-right: 10px;
    color: #414141; /* TEST */
}

.notification-inner__title_message_title {
    max-width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    vertical-align: bottom;
    display: inline-block;
}

.notification-inner__title_message_talk-id {
    font-size: 10px;
    flex-shrink: 0;
    color: #fff;
    border-radius: 3px;
    max-width: 50px;
    white-space: nowrap;
    overflow: hidden;
    padding: 1px 3px 0;
    background: #58b98f;
    float : right;
}

.notification-inner__title_message_pinned {
    color: #676e79 !important;
    float: right;
    margin-right: 15px;
}

.control-user_state {
    display: none;
    height: 6px;
    width: 6px;
    border-radius: 50%;
    margin-left: 5px;
    vertical-align: middle;
    padding: 0;
    border: 0;
    white-space: nowrap;
}

.notification-inner__data_message {
    color: #676e79;
    padding-top: 1.4px;
    font-size: 13px;
    float: right;
    margin: 0;
    border: 0;
}

.notification-inner__from__message {
    font-size: 15px;
    word-break: break-word;
    margin-top: 1px;
    line-height: 19px;
    color: #a1a5a8;
    display: flex;
    flex-direction: column;
    border: 0;
    vertical-align: baseline;
    text-rendering: geometricPrecision;
    -webkit-font-smoothing: antialiased;
}

.notification-inner__from__message_height-h3 {
    max-height: 38px;
    position: relative;
    overflow: hidden;
    margin: 0;
    padding: 0;
    border: 0;
    word-break: break-word;
    color: #808588;
    cursor: pointer;
}

.notification-chat__container-icon img {
    width: 14px;
    height: 14px;
    margin: 2px;
}

.feed-active {
    background: #f6f6f6 !important;
}

.message_not_read {
    padding: 1px 5px 2px;
    font-size: 11px;
    font-weight: 600;
    flex-shrink: 0;
    color: #fff;
    border-radius: 3px;
    max-width: 50px;
    white-space: nowrap;
    overflow: hidden;
    background: #cdcdcd;
    margin-left: 5px;
}

.ReactCollapse--collapse {
    transition: all 0.3s ease-in-out 0s;
}

.arrow_down img {
    transition: transform 0.3s ease 0s;
}

.arrow_down[data-is-active=true] img {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
}

#chats-block {
    padding: 0;
}

.search-container {
    padding-top : 10px;
    padding-right : 10px;
    padding-left: 20px;
}

.chat-title {
    padding-left: 10px;
    padding-bottom: 10px;
}

.empty-chats {
    text-align: center;
}

.empty-chats > h3 {
    font-weight: normal !important;
    margin-top: 0!important;
    color: #808588;
}

#btn_filter {
    color : green;
    cursor: pointer;
}

.chat-feed-avatar {
    width: 40px;
    height: 40px;
}

:root {
    --background-avatar-color: #FF5733;
}

.chat-feed-avatar:after {
    content: attr(alt);
    text-align: center;
    font-size: 20px;
    font-weight: 600;
    color: #ffffff;
    z-index: 10;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(#ffffff -125%, var(--background-avatar-color));
    box-shadow: 0 0 0 2px #d5d5d5;
    object-fit: cover;
    overflow: hidden;
    background-size: cover;
    background-position: center;
    border-radius: 50%;
}