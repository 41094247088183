.sponge-bob-dd-limited {
    max-width: 200px;
    white-space: nowrap;
    overflow: hidden;
    display: inline-block;
    text-overflow: ellipsis;
}

.sponge-bob .bob-field {
    cursor: pointer;
    line-height: 16px;
    padding: 8px 0 0 0;
    width: 46px;
    height: 46px;
    overflow: hidden;
}

.sponge-bob .bob-field .bob-done {
    position: absolute;
    margin-top: -9px;
    margin-left: -7px;
    opacity: 0.5;
}

.sponge-bob .bob-divider {
    width: 46px;
    height: 46px;
    overflow: hidden;
    cursor: default;
    line-height: 16px;
    padding: 6px 0 0 0;
    border: 1px dashed #eee;
    font-size: 90%;
    color: green;
}

.sponge-bob .bob-divider i {
    font-size: 130%;
}

.sponge-bob-legend span {
    padding: 6px;
    width: 46px;
    height: 33px;
    display: inline-block;
    text-align: center;
}

.sponge-bob-legend .bob-done {
    position: absolute;
    margin-left: -7px;
    margin-top: -7px;
    opacity: 0.5;
}

.sponge-bob, .sponge-bob-legend {
    user-select: none;
}

.red_flash_bob {
    animation: blinker 1.5s linear infinite;
}