body {
    font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
    background-color: #f3f3f4;
    font-size: 13px;
    color: #1d2129;
    overflow-x: hidden;
}

.modal {
    background-color: rgb(0,0,0);
    background-color: rgba(0,0,0,0.4);
    overflow-x: hidden;
    overflow-y: auto;
}

.form-branch {
    padding-top: 5px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column
}

.rates-strong {
    display: inline-block;
    max-width: 70%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden
}

.dew_discount_end_hint.text-muted {
    color: #999;
}
.dew_discount_end_hint {
    cursor: text;
    position: absolute;
    margin-top: 8px;
    margin-left: 12px;
}

.dew_discount_end_hint .period, .dew_discount_end_hint .picker {
    cursor: pointer;
    border-bottom: 1px #999 dashed;
}

.dew_discount_end_autocomplete {
    padding-left: 10px;
}

.archive * {
    text-decoration: inherit;
}

.text-none {
    text-decoration: none;
}

.text-line-through {
    text-decoration: line-through !important;
}

.is_stuck {
    position: fixed;
    top: 0;
    width: auto;
    left: 0;
    right: 0;
}

.date-input::-webkit-calendar-picker-indicator { display: none }

@media (max-width: 1200px) {
    #block-center {
        float: none;
        width: 100%;
    }
}

.b-b-none {
    border-bottom: none !important;
}

/*SIDE MENU*/
@media (min-width: 768px) {
    #page-wrapper {
        position: inherit;
        margin: 0 0 0 150px;
    }
}

@media (min-width: 768px) {
    .navbar-static-side {
        position: absolute;
        width: max-content;
    }
}

#sidebar-collapse {
    width: max-content!important;
}

.navbar .alfa-header {
    width: 119px;
}

.label-default, .label-primary, .label-danger {
    float: right;
    margin: 5px 0 0 2px;
    padding: 2px 3px;
    position: relative;
    z-index: 2;
}

/* SCROLL BAR */

/* Firefox */
* {
    scrollbar-width: auto;
    scrollbar-color: #bfbfbf #ffffff;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
    width: 11px;
}

*::-webkit-scrollbar-track {
    background: #e3e3e3;
}

*::-webkit-scrollbar-thumb {
    background-color: #bfbfbf;
    border-radius: 10px;
    border: 3px solid #e3e3e3;
}

.scroll-block::-webkit-scrollbar-track {
    background: #ffffff;
}

.scroll-block::-webkit-scrollbar-thumb {
    background-color: #bfbfbf;
    border-radius: 10px;
    border: 5px solid #ffffff;
}

.inbox_message::-webkit-scrollbar {
    width: 5px;
}

#a-m-xs {
    margin: 5px;
}

.logo {
    font-family: "Open Sans", sans-serif;
    letter-spacing: 1.3px;
}

.hide-arrows::-webkit-outer-spin-button,
.hide-arrows::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.btn {
    transition: background-color .5s, border-color .5s;
}

.crm-hover-block {
    transition: background-color .2s;
}

.nav > li > a {
    transition: background-color .2s;
}

.sk-margin > div {
    margin-right: 2px;
}

.bg-blue {
    background-color: rgba(110, 214, 240, 0.2);
}

.text-blue {
    color : #0a5584;
}

.text-decoration-0 {
    text-decoration: none!important;
}

.dashboard-item {
    border: 1px solid transparent;
}

.dashboard-item-head {
    display: block;
    padding: 10px;
    cursor: pointer;
    color: #333;
}

.dashboard-item input[type=checkbox] {
    margin-top: 1px;
    opacity: 0;
}

.dashboard-item.selected {
    border: 1px solid  #f2f2f2;
}

.dashboard-item:hover input[type=checkbox] {
    opacity: 1;
}

.highcharts-legend-item {
    font-size: 15px !important;
}

.table-text-center > thead > tr > th, .table-text-center > tbody > tr > td {
    text-align: center;
}

.textarea {
    display: block;
    width: 100%;
    overflow: hidden;
    resize: both;
    line-height: 20px;
    height: auto !important;
    white-space: pre-wrap;
}

.textarea[contenteditable]:empty::before {
    color: gray;
}

audio::-webkit-media-controls-current-time-display {
    display: none;
}

audio::-webkit-media-controls-time-remaining-display {
    display: none;
}

.flex {
    display: flex !important;
}

.items-center {
    align-items: center !important;
}

.flex-1 {
    flex: 1 !important;
}

.c-pointer {
    cursor: pointer !important;
}