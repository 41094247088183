.dark, .dark .popover, .dark .emoji-picker-react, .dark .popover-title, .dark .footer, .dark .modal-content, .dark .modal-title, .dark .btn-white, .dark .ibox-content, .dark .input-group-addon, .dark .modal-footer, .dark .table>tbody>tr>td, .dark .fix-header, .dark .fix-footer, .dark .modal-header, .dark .dropdown-menu, .dark .chosen-single, .dark .chosen-drop, .dark .chosen-container a, .dark .chosen-choices, .dark .fc-button, .dark .fc-scrollgrid-sync-inner, .dark .fc-timegrid-axis, .dark .pagination>li>a, .dark .pagination>.disabled>span, .dark .hr-line-dashed, .dark .ui-menu, .dark .table-bordered>thead>tr>td, .dark .form-control {
    color: white!important;
    background: rgb(53, 53, 53)!important;
    border-color: rgb(73, 73, 73)!important;
}

.dark .feed-dont-read {
    background: #4e4e52;
}

.dark .feed-message-item {
    border-bottom: 1px solid rgb(73, 73, 73)!important;
}

.dark .sticky-date > span {
    background: rgb(48 47 47);
    color: #fff;
}

.dark .notification-inner__container_text h2 {
    color : #d0d0d0!important;
}

.dark .feed-note__content  {
    background: rgb(33,33,33);
    color: white;
}

.dark .svg-appendix-client .corner {
    fill: rgb(33,33,33);
}

.dark .feed-active {
    background: #3b3b3b !important;
}

.dark .feed-note-wrapper, .dark .message-input-wrapper, .dark .emoji-picker-react .emoji-group:before {
    background: rgb(53, 53, 53)!important;
}

.dark .bg-warning a {
    color: #6d6c6c!important;
}

.dark #pay-table {
    border: 1px solid rgb(73, 73, 73)!important;
}

.dark #show-pass {
    color: rgb(53, 53, 53)!important;
}

.dark .message_not_read {
    background: #656565;
}

.dark .message-emoji, .dark #attach-menu-button {
    color: white;
}

.dark .sweet-alert, .dark .sweet-alert h2, .dark .sweet-alert #sweet-text {
    color: white!important;
    background: rgb(53, 53, 53)!important;
    border-color: rgb(73, 73, 73)!important;
}

.dark .confirm, .dark button.cancel {
    background-color: rgb(53, 53, 53)!important;
    box-shadow: rgb(73 73 73 / 80%) 0px 0px 2px, rgb(0 0 0 / 10%) 0px 0px 0px 1px inset !important
}

.dark .fc-timegrid-event-harness-inset .fc-timegrid-event {
    box-shadow: none!important;
}

.dark .board-columns h3 {
    color: white!important;
}

.dark a {
    color: white!important;
}

.dark .agile-list li {
    background: rgb(53, 53, 53)!important;
    border-color: rgb(73, 73, 73)!important;
}

.dark .gray-bg {
    background: rgb(73, 73, 73);
}

.dark li .gray-bg a {
    color: inherit!important;
}

.dark .tabs-container .nav-tabs > li.active > a, .dark .tabs-container .nav-tabs > li.active > a:hover,.dark .tabs-container .nav-tabs > li.active > a:focus {
    background: rgb(53, 53, 53);
    border-color: rgb(73, 73, 73);
}

.dark .tabs-container .nav-tabs > li.active > a {
    border-bottom-color: transparent;
}

.dark .bg-warning {
    color: #1d2129;
}

.dark .close {color: white}

.dark .nav>li>a:hover, .dark .nav>li.active>a, .dark .form-control[disabled] {
    background-color: rgb(73, 73, 73);
}

.dark .dropdown-menu>li>a:hover, .dark .pagination > .active > a {
    background-color: rgb(73, 73, 73)!important;
}

.dark .search-choice a {
    background: url(https://cdn.alfacrm.com/chosen/chosen-sprite.png) -42px 1px no-repeat!important;
}

.dark .panel-body {
    background: rgb(53, 53, 53);
    border: 1px solid rgb(73, 73, 73);
}

.dark .fc-button-active {
    background-color: rgb(73, 73, 73)!important;
}

.dark input, .dark select, .dark textarea, .dark .tabs-container, .dark .white-bg {
    color: white;
    background: rgb(53, 53, 53);
    border-color: rgb(73, 73, 73)!important;
}

.dark #page-wrapper {
    color: white;
    background: rgb(53, 53, 53);
}

.dark .border-right, .dark .border-bottom {
    border-right-color: rgb(73, 73, 73)!important;
}

.dark .border-bottom {
    border-bottom-color: rgb(73, 73, 73)!important;
}

.dark .settings-block__menu, .dark hr, .dark .nav-tabs, .dark .fc-scrollgrid, .dark td[role="presentation"], .dark th[role="presentation"], .dark .fc-col-header-cell {
    border-color: rgb(73, 73, 73)!important;
}

.dark .fc-timegrid-slot {
    border-color: rgb(115, 115, 115);
}

.dark .alert-warning {
    background-color: rgba(252, 248, 227, 0.18);
    border-color: #faebcc;
}

.dark .text-muted, .dark .gray-bg {
    color : #9b9b9b;
}

.dark a.fc-event {
    background-color: rgb(53, 53, 53)!important;
}

.dark .fc-event-time {
    color: white!important;
}

.dark .fc-event-title {
    color: white!important;
}

.dark .crm-hover-block:hover, .dark .highlight-checkbox label:hover, .dark .ui-menu-item-wrapper:hover, .dark .nav.navbar-top-links > li:hover > a {
    background: rgb(73, 73, 73)!important;
}