
.board-columns > .row {
    overflow-x: auto;
    white-space: nowrap;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
.board-columns > .row > .board-column {
    display: inline-block;
    float: none;
    vertical-align: top;
}



.board-action {display: none}
ul.lead-list li:hover .board-action {display: inline}
ul.lead-list li:hover .board-action-replace {display: none}
.horizontal-scroll {cursor: all-scroll}