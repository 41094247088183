
.settings-block__content .modal-footer {text-align: left}
.settings-block__menu {padding-top: 35px}
.settings-block__menu-title {padding: 5px 10px}
.settings-block__list {padding: 0;margin: 0 0 30px;list-style: none}
.settings-block__list li a {display: block;padding: 5px 10px;border: 1px solid transparent}
.settings-block__list li.active a {background-color: #eee}
.settings-block__content .modal-header {border-bottom: none;background: none;padding: 15px 25px}
.loading-settings .modal-content {display: none}
.loading-settings .settings-block__content {position: relative}
.settings-block__loading {display: none}
.loading-settings .settings-block__loading {display: flex !important;align-items: center;justify-content: center;height: 70vh}
.settings-block .modal-content {box-shadow: none;position: initial;border: none}
.settings-block__content {float: left;width: 75%;padding-left: 2vw}
.settings-block__menu {float: left;width: 25%;padding-right: 10px;border-right: 1px solid #e7eaec}
.settings-block .modal-header button.close {display: none}
label {font-weight: 400}
.mass-checkboxes label {margin-bottom: 0}


@media (max-width: 767px) {
    .settings-block__menu, .settings-block__content {float: none;width: 100%;padding-left: 0;border-right:0;padding-right:0}
    .loading-settings .settings-block__menu, .load-settings .settings-block__menu {display: none}
    .settings-block__content {position: absolute;z-index: 2;background: #fff;top: 0;left: 0;width: 100%}
    .settings-block__content .modal-header span.settings-close:before {content: '‹';color:#4995c5;font-size:26px;height:50px;line-height:0;padding: 22px 24px 20px 27px;position:absolute;left:0;top:0;cursor:pointer}
    .modal-header h4 {padding-left: 25px;}
    .settings-block .modal-content {background: #fff}
    .settings-block__list.nav li a {color: #4995c5 !important;border-bottom: 1px solid #e5e6e7;padding-right: 0;line-height: 26px}
    .settings-block__list.nav li a:before {content: '›';display: block;float: right;font-size: 18px;line-height: 18px}
    .settings-block__list.nav li.active a {border: 1px solid transparent}
}
@media (min-width: 992px) {
    .settings-block__content-content {width: 100%;max-width: 900px}
    #settings-container {width: 100%;max-width: 600px}
}
@media (max-width: 1390px) and (min-width: 767px) {
    #enrollwizardform-config_info {font-size: smaller}
}