label {
    font-weight: normal;
}
.crm-toggle-all .action-toggle-all, .crm-toggle-all.active .action-toggle-none
{
    display: none;
}
.crm-toggle-all .action-toggle-none, .crm-toggle-all.active .action-toggle-all
{
    display: inline;
}