.task-text {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    padding: 3px 0 2px 0;
}

.agile-board-body {
    cursor: ew-resize;
}

.agile-board-body > .row {
    overflow-x: auto;
    white-space: nowrap;
}

.agile-board-body > .row > .col-sm-3 {
    display: inline-block;
    float: none;
    vertical-align: top;
}