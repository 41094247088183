.table-responsive_component > .details-table > thead > tr {
    white-space: nowrap;
}

.max_767, .min_768 {
    display: none;
}

.previous-grade-item {
    padding: 0 0 5px 0;
    font-size: 13px;
    color: darkorange;
}

@media (max-width: 767px) {
    .max_767 {
        display: initial;
    }
}

@media (min-width: 768px) {
    .previous-grade-header {
        color: darkorange;
    }

    .previous-grade-item {
        display: flex;
        align-items: center;
        position:absolute;
        left: 0;
        padding: 0 5px;
        width: 20%;
        height: 30px;
        transform: translate(-100%, 0);
        cursor:default;
    }

    .min_768 {
        display: initial;
    }
}


.table-responsive_component {
    overflow-x: inherit;
}
.details-grade ~ .chosen-container-single .chosen-single {
    padding: 2px 10px;
}
.details-grade ~ .chosen-container-single .chosen-single-with-deselect span {
    margin-right: 24px;
}
.details-grade ~ .chosen-container-single .chosen-single.chosen-default span {
    margin-right: 12px;
}
.details-grade ~ .chosen-container-single .chosen-results > li {
    margin-right: 2px;
}
.details-grade ~ .chosen-container-single .chosen-single abbr {
    top: 9px;
    right: 20px;
}
.details-grade ~ .chosen-container-single .chosen-single div b {
    background-position: 0 5px;
}
.details-grade ~ .chosen-container-single .chosen-drop .chosen-results::-webkit-scrollbar {
    width: 4px;
}
.details-grade ~ .chosen-container-single .chosen-drop .chosen-results::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 2px;
}
.details-grade ~ .chosen-container-single .chosen-drop .chosen-results::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 2px;
}
@media (min-width: 768px) {
    .bl-none-desktop {
        border-left: none;
    }
    .pl-none-desktop {
        padding-left: 0;
    }
    .pr-none-desktop {
        padding-right: 0;
    }
}