span.attention {
    float: right;
    margin: -8px -8px -8px 0;
    border: 6px solid transparent;
    border-top: 6px solid lightblue;
    border-right: 6px solid lightblue;
}

span.legend-item {
    width: 30px;
    display: inline-block;
    border: 1px solid #ccc;
}