.fix-header {
    background: repeating-linear-gradient(135deg, #f7f7f7, #fff 5px, #f7f7f7, #fff 5px);
    border-bottom: 1px solid #f7f7f7;
    padding: 15px;
}

.fix-header .close {
    font-size: 33px;
    font-weight: normal;
    margin-top: -2px;
    position: absolute;
    right: 13px;
    top: 9px;
    opacity: 0.5;
}

.fix-footer {
    border-top: 1px solid #f7f7f7;
    margin-top: 0;
    padding: 15px;
    text-align: right;
}

.fix-footer [type="submit"] {
    margin-left: 5px;
}