.confirm {
    display: inline-block;
    background-color: rgb(140, 212, 245) !important;
    box-shadow: rgba(140, 212, 245, 0.8) 0px 0px 2px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px inset !important;
}

.confirm:hover {
    background-color: rgb(134, 204, 235) !important;
}

.cancel {
    display: inline-block;
    box-shadow: none;
}