.dew_customertotariff_e_date_hint.text-muted
{
    color: #999;
}
.dew_customertotariff_e_date_hint
{
    cursor: text;
    position: absolute;
    margin-top: 8px;
    margin-left: 12px;
}

.dew_customertotariff_e_date_hint .period, .dew_customertotariff_e_date_hint .picker
{
    cursor: pointer;
    border-bottom: 1px #999 dashed;
}

.dew_customertotariff_e_date_autocomplete
{
    padding-left: 10px;
}

.highlight-checkbox label {
    cursor: pointer;
    margin: 0;
    padding: 2px 5px;
    border-radius: 2px;
}

.highlight-checkbox label:hover {
    background-color: #efefef;
}

.highlight-checkbox label {
    cursor: pointer;
    margin: 0;
    padding: 2px 5px;
    border-radius: 2px;
    user-select: none;
}

.highlight-checkbox label:hover {
    background-color: #efefef;
}

.period-end-container label {
    cursor: text;
    position: absolute;
    margin-top: 8px;
    margin-left: 12px;
}
.period-end-container .period {
    cursor: pointer;
    border-bottom: 1px #999 dashed;
}