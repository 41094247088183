.ui-menu-item-wrapper:hover {
    border: 1px solid #dddddd;
    background: #ffffff;
    font-weight: normal;
    color: #212121;
    margin: -1px;
}

.ui-link {
    text-decoration: none!important;
}

.navbar-top-links li {
    display: block;
}