a.fc-event
{
    background-color: #fff;
    border: 1px solid #a8a8a8;
    border-bottom: 6px solid #a8a8a8;
    color: #1d2129;
}

.fc-event-time, .fc-event-title {
    color : black;
}

a.fc-event i, a.fc-event input
{
    margin-right: 3px;
}

.fc-month-view .fc-day
{
    cursor: pointer;
}

/* запланирован */
a.fc-event.status1
{
}

/* отменён */
a.fc-event.status2
{
    text-decoration: line-through;
}

/* проведён */
a.fc-event.status3
{

}

/*HEADER*/
.fc-button-primary {
    background-color: #ffffff!important;
    color: #1d2129!important;
    background-image: none;
    background-repeat: repeat-x;
    box-shadow: none!important;
    text-shadow: none;
}

.fc-button {
    line-height: 1.9em!important;
    overflow: hidden;
    color: inherit;
    border: 1px solid #e7eaec!important;
    cursor: pointer;
    display: inline-block;
}

.fc .fc-button {
    padding: 0 0.6em;
}

.fc button .fc-icon {
    margin: 0!important;
}

.fc-button-active {
    background-color: #1ab394!important;
    border-color: #1ab394!important;
    color: #ffffff!important;
}

.fc-media-screen {
    user-select: none;
}

.fc-highlight {
    background: #bce8f1!important;
    opacity: .3!important;
}

.fc-more-link {
    text-decoration: none;
    cursor: pointer;
    margin: 1px 3px;
    color: #226895;
    text-align: center;
}

.fc-daygrid-event .fc-event-time {
    font-weight: 700;
}

.text-dec-line {
    text-decoration: line-through!important;
}

.red_flash {
    animation: blinker 1.5s linear infinite;
}