/* CHAT */
.chat-bg-wrapper {
    background-image: url("../../Assets/image/chat-bg-pattern.png") !important;
    background-position: top right !important;
    background-size: 510px auto !important;
    background-repeat: repeat !important;
    background-color: #0a2331 !important;
    mix-blend-mode: unset;
    border-radius: 10px !important;
    padding: 5px 10px 10px 10px !important;
}

.chat-header {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #FFFFFF;
    padding: 5px;
    border-radius: 50px;
    border-bottom: 2px solid #000000;
    margin-bottom: 5px;
}

.chat-header .caption {
    font-weight: 800;
    text-transform: uppercase;
    letter-spacing: 1px;
}

.chat-header .dropdown-menu {
    background-color: rgb(33,33,33);
    border-radius: 10px !important;
}

.chat-header .dropdown-menu > li > a {
    border-radius: 10px !important;
}

.chat-header .dropdown-menu > li > a:hover {
    color: #FFFFFF !important;
    background-color: rgb(49, 49, 49) !important;
    border-radius: 10px !important;
}

.feed-note-wrapper {
    border-radius: 5px;
    position: relative;
    margin: 0;
    padding: 0;
}

.feed-note-fixer {
    width: 100%;
}

.feed-note {
    display: flex;
    position: relative;
    font-size: 15px;
    color: #313942;
    border-radius: 3px;
    padding: 10px 10px 15px;
    box-sizing: border-box;
    width: 100%;
}

.feed-note-icon {
    align-self: flex-end;
    position: relative;
    height: 36px;
    width: 36px;
    box-sizing: border-box;
    margin-right: 10px;
    margin-top: 2px;
    display: flex;
    flex-shrink: 0;
}

.feed-note-wrapper .feed-note__avatar {
    width: 36px;
    height: 36px;
    border-radius: 36px;
    display: block;
    background-size: cover;
    overflow: hidden;
}

.n-avatar {
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
}

.feed-note-wrapper .feed-note__icon-origin {
    position: absolute;
    bottom: -2px;
    right: -5px;
    background: #fff;
    display: flex;
    border-radius: 50%;
    padding: 2px;
}

.feed-note__content {
    background: #f5f5f5;
    position: relative;
    width: auto;
    max-width: 70%;
    min-height: 0;
    flex-grow: 0;
    border-radius: 10px;
    /*padding: 7px 9px 9px;*/
    display: flex;
    flex-direction: column;
    color: #313942;
    padding: 0.3125rem 0.5rem 0.375rem;
    border-bottom-left-radius: 0;
}

.feed-note__header {
    color: #6b6d72;
    display: flex;
    font-size: 13px;
    line-height: 20px;
    overflow: hidden;
}

.feed-note__header-inner {
    overflow: visible;
    display: flex;
    white-space: nowrap;
    position: relative;
    align-items: center;
    width: 100%;
    order: 1;
    flex: 0 1 auto;
    min-width: 0;
}

.feed-note__header-inner-nowrap {
    display: flex;
    flex-shrink: 0;
    white-space: inherit;
}

.feed-note__date {
    padding-right: .25em;
    white-space: nowrap;
    flex-shrink: 0;
}

.feed-note__user {
    max-width: 100%;
    cursor: pointer;
    overflow: hidden;
    text-overflow: ellipsis;
}

.feed-note__body {
    overflow: visible;
    padding: 0;
    flex: 1 1 auto;
    word-break: break-word;
}

.feed-note__joined-attach {
    position: relative;
}

.feed-note__message {
    line-height: 20px;
    white-space: break-spaces;
    word-wrap: break-word;
    word-break: break-word;
}

.feed-note__delete {
    float: right;
    margin-left: 5px;
    cursor: pointer;
}

.feed-note__talk-outgoing {
    bottom: 20px;
    position: unset;
    margin-left: auto;
    align-self: flex-end;
    margin-top: 20px;
    display: flex;
    align-items: center;
    right: 10px;
    font-size: 13px;
    white-space: nowrap;
}

.feed-note__talk-outgoing-wrapper {
    position: relative;
    display: flex;
    align-items: center;
    order: -1;
    margin-right: 6px;
    height: 24px;
    line-height: 19px;
    cursor: pointer;
}

.feed-note__talk-outgoing-close {
    border-radius: 3px;
    display: flex;
    align-items: center;
    height: 24px;
    padding: 3px 8px 3px 7px;
    box-sizing: border-box;
    background: linear-gradient(180deg,#fff 0%,#f8f8f8 100%);
    border: .8px solid #bfc4c5;
    color: #6b6d72;
}

.feed-note__talk-outgoing-close__text {
    cursor: pointer;
}

.feed-note__talk-outgoing-wrapper_with-read {
    position: relative;
    display: flex;
    align-items: center;
    order: -1;
    margin-right: 6px;
    height: 24px;
    line-height: 19px;
    cursor: pointer;
}

.feed-note__talk-outgoing-read {
    display: flex;
    align-items: center;
    height: 24px;
    padding: 3px 8px 3px 7px;
    box-sizing: border-box;
    background: linear-gradient(180deg,#fff 0%,#f8f8f8 100%);
    border: .8px solid #bfc4c5;
    border-radius: 3px;
    color: #6b6d72;
}

.feed-note-out {
    flex-direction: row-reverse;
}

.feed-note-out .feed-note-icon {
    margin-left: 10px;
    margin-right: 0;
    align-self: flex-end;
    position: relative;
    height: 36px;
}

.feed-note-out .feed-note__content {
    color: #fff;
    position: relative;
    width: auto;
    max-width: 70%;
    min-height: 0;
    flex-grow: 0;
    background: rgb(118,106,200)!important;
    border-radius: 10px;
    /*padding: 7px 9px 9px;*/
    padding: 0.3125rem 0.5rem 0.375rem;
    border-bottom-right-radius: 0;
}

.svg-appendix {
    right: 0.38rem;
    overflow: hidden;
    position: absolute;
    bottom: 1.5rem;
    width: 0.625rem;
    height: 1.525rem;
    font-size: 1rem !important;
}

.svg-appendix-client {
    left: -0.762rem;
    overflow: hidden;
    position: absolute;
    bottom: 0;
    width: 0.825rem;
    height: 1.525rem;
    font-size: 1rem !important;
}

.svg-appendix .corner {
    fill: rgb(118,106,200);
}

.svg-appendix-client .corner {
    fill: #f5f5f5;
}

.feed-note-out .feed-note__header {
    color: rgba(255,255,255,.7);
}

/* INPUT */

.message-input-wrapper {
    padding: 7px;
    border-radius: 50px;
    opacity: 1;
    display: flex;
    box-sizing: border-box;
    position: sticky;
    background-color: rgb(33,33,33);
}

.edit-message-wrapper {
    height: 4rem;
    transition: height 150ms ease-out,opacity 150ms ease-out;
    display: flex;
    margin-bottom: 10px;
}

.edit-message-icon {
    font-size: 18px;
    flex-shrink: 0;
    background: none !important;
    width: 3.5rem;
    height: 3.5rem;
    margin: 0 1.2rem 0 0;
    padding: 0;
    display: grid;
    place-content: center;
}

.edit-message-text-wrapper {
    padding-inline-start: 0.5625rem;
    width: 100%;
    display: flex;
    align-items: center;
    font-size: 16px;
    line-height: 1.125rem;
    margin: 0 1.5rem 0.0625rem;
    padding: 0.1875rem 0.25rem 0.1875rem 0.4375rem;
    position: relative;
    overflow: hidden;
    cursor: pointer;
    border-left: 3px solid rgb(118,106,200);
    direction: ltr;
    --slide-transition: 450ms cubic-bezier(0.25, 1, 0.5, 1);
    --composer-hidden-scale: 0.924188;
    --toolbar-hidden-scale: 1.08203;
    --unpin-hidden-scale: 1.9845;
    --toolbar-unpin-hidden-scale: 0.503906;
    --composer-translate-x: 34.625px;
    --toolbar-translate-x: -29.574px;
    --pattern-color: #0A0A0A8C;
    --theme-background-color: #0F0F0F;
}

.edit-message-text {
    margin-inline-start: 0.375rem;
    flex-grow: 1;
    overflow: hidden;
    display: flex;
    flex-direction: column-reverse;
    line-height: 2rem;
    font-size: 14px;
    cursor: pointer;
    direction: ltr;
    --slide-transition: 450ms cubic-bezier(0.25, 1, 0.5, 1);
}

.edit-message-text p {
    color: #FFFFFF !important;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    height: 1.125rem;
    margin-bottom: 0;
    flex: 1;
    margin-top: 0;
}

.message-title {
    color: rgb(118,106,200) !important;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: 0.125rem;
    flex: 1;
    display: block;
    font-weight: 500;
    font-size: 13px;
}

.edit-message-close {
    color: #FFFFFF !important;
    font-size: 30px;
    flex-shrink: 0;
    background: none !important;
    width: 2.25rem;
    height: 2.875rem;
    margin: 0 0.50rem 0 0.75rem;
    padding: 0;
    align-self: center;
    opacity: .8;
    outline: none !important;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 0;
    line-height: 1.2;
    cursor: pointer;
    text-transform: uppercase;
    position: relative;
    overflow: hidden;
    transition: background-color .15s,color .15s;
    text-decoration: none !important;
    --premium-gradient: linear-gradient(88.39deg, #6C93FF -2.56%, #976FFF 51.27%, #DF69D1 107.39%);
}

.message-emoji {
    border-radius: 50%;
    color: rgb(170,170,170,0.8);
    flex-shrink: 0;
    background: none !important;
    width: 3.5rem;
    height: 3.5rem;
    margin: 0;
    padding: 0;
    align-self: flex-end;
    outline: none !important;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 0;
    background-color: rgba(0,0,0,0);
    background-size: cover;
    line-height: 1.2;
    cursor: pointer;
    text-transform: uppercase;
    position: relative;
    overflow: hidden;
    transition: background-color .15s,color .15s;
    text-decoration: none !important;
    --premium-gradient: linear-gradient(88.39deg, #6C93FF -2.56%, #976FFF 51.27%, #DF69D1 107.39%);
}

.message-emoji:hover {
    color: rgb(135,116,225) !important;
}

.message-input-text {
    width: 80%;
    flex-grow: 1;
    position: relative;
    box-sizing: border-box;
}

.input-scroller {
    overflow-y: auto;
    max-height: 26rem;
    transition: height 100ms ease;
}

.input-scroller > .input-scroller-content {
    position: relative;
}

.chatMenu {
    align-self: flex-end;
    box-sizing: border-box;
}

.chat-menu-button {
    color: rgb(170,170,170,0.8);
    flex-shrink: 0;
    background: none !important;
    width: 2.3rem;
    height: 3.3rem;
    margin: 0;
    padding: 0;
    align-self: flex-end;
    border-radius: 50%;
    outline: none !important;
    display: flex !important;
    align-items: center;
    justify-content: center;
    border: 0;
    background-color: rgba(0,0,0,0);
    background-size: cover;
    line-height: 1.2;
    cursor: pointer;
    text-transform: uppercase;
    position: relative;
    overflow: hidden;
    transition: background-color .15s,color .15s;
    text-decoration: none !important;
    --premium-gradient: linear-gradient(88.39deg, #6C93FF -2.56%, #976FFF 51.27%, #DF69D1 107.39%);
}

.send-message {
    flex-shrink: 0;
    background: none !important;
    width: 2.1rem;
    height: 3.5rem;
    margin: 0;
    padding: 0;
    align-self: flex-end;
    border-radius: 50%;
    outline: none !important;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 0;
    color: rgb(170,170,170,0.8);
    background-color: rgb(170,170,170,0.8);
    background-size: cover;
    line-height: 1.2;
    cursor: pointer;
    text-transform: uppercase;
    position: relative;
    overflow: hidden;
    transition: background-color .15s,color .15s;
    text-decoration: none !important;
    --premium-gradient: linear-gradient(88.39deg, #6C93FF -2.56%, #976FFF 51.27%, #DF69D1 107.39%);
}

.send-message a {
    color: rgb(170,170,170,0.8);
}

/* LOGO */

.viber {
    background-image: url("../../Assets/image/viber.jpg");
}

.telegram {
    background-image: url("../../Assets/image/telegram.webp");
}

.emoji-picker-react {
    z-index: 9999;
    position: absolute !important;
    bottom: 55px;
    left: 0;
    box-shadow: none!important;
    display: none!important;
    background-color: rgb(33,33,33) !important;
    border-color: rgb(33,33,33) !important;
    border-radius: 30px !important;
    width: 300px !important;
    padding: 5px;
}

.emoji-picker-react .emoji-categories button {
    background-color: #eeeeee !important;
    border-radius: 100px;
}

.emoji-picker-react .emoji-group:before {
    background-color: rgb(33,33,33) !important;
}

.display-flex {
    display: flex!important;
}

.sticky-date {
    z-index: 1;
    position: sticky;
    top: 0.625rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
    opacity: 1;
    transition: opacity .3s ease;
    text-align: center;
    user-select: none;
}

.sticky-date > span {
    width: 76.3px;
    display: inline-block;
    background: rgb(33,33,33);
    color: #FFFFFF;
    font-weight: 500;
    line-height: 1.75;
    padding: 0 0.5rem;
    border-radius: 0.75rem;
    word-break: break-word;
    position: relative;
    z-index: 0;
}

/* MESSAGE MENU */
.MessageContextMenu {
    position: absolute;
    font-size: 1rem;
    user-select: none;
    display: none;
}

.bubble {
    background: rgba(33,33,33,0.866667);
    transform-origin: 219px 248px;
    max-height: 545.6px;
    /*backdrop-filter: blur(10px);*/
    right : 0;
    bottom : calc(100% + 0.5rem);
    min-width: 13.5rem;
    width: auto;
    transition: opacity .15s cubic-bezier(0.2, 0, 0.2, 1),transform .15s cubic-bezier(0.2, 0, 0.2, 1) !important;
    overflow: initial;
    padding: 0 !important;
    display: block;
    list-style: none;
    margin: 0;
    position: absolute;
    overscroll-behavior: contain;
    z-index: 21;
    border-radius: 0.75rem;
    opacity: 1;
}

@media (max-width: 767px) {
    .bubble {
        display: flex;
    }
}

.scrollable-content {
    text-decoration: none !important;
    padding: 0.25rem 0;
    overscroll-behavior: contain;
    transition: scrollbar-color 0.3s ease;
    pointer-events: auto;
    user-select: none;
}

.MenuItem.compact {
    font-size: 13px;
    margin: .125rem .25rem!important;
    padding: .25rem!important;
    border-radius: .375rem;
    width: auto;
    font-weight: 500;
    transform: scale(1);
    transition: .15s ease-in-out transform;
}

.MenuItem {
    width: 100%;
    background: none;
    border: none !important;
    box-shadow: none !important;
    outline: none !important;
    display: flex;
    align-items: center;
    padding: .75rem 1rem;
    position: relative;
    overflow: hidden;
    line-height: 2rem;
    white-space: nowrap;
    color: rgb(255,255,255);
    --ripple-color: rgba(0, 0, 0, 0.08);
    cursor: pointer;
    unicode-bidi: plaintext;
}

.MenuItem > i {
    max-width: 1.25rem;
    font-size: 1.25rem;
    margin-left: 0.5rem;
    margin-right: 1.25rem;
}

.MessageMeta {
    position: relative;
    top: 0.375rem;
    bottom: auto !important;
    float: right;
    line-height: 1.35;
    height: 21px;
    margin-left: 0.7rem;
    margin-right: -0.5rem;
}

.MessageMeta .message-time {
    margin-right: 0.5rem;
}

.MessageMeta .message-time {
    font-size: 1.1rem;
    white-space: nowrap;
}

.media-inner {
    cursor: pointer;
    overflow: hidden;
}

.short-media {
    width: 300px;
    height: 100%;
    border-radius: .75rem;
    display: block;
    object-fit: cover;
    user-select: none;
}

.full-media {
    width: 100%;
    height: 100%;
    border-radius: .75rem;
    display: block;
    object-fit: cover;
    user-select: none;
}

.media-inner .MessageMeta {
    display: flex;
    align-items: center;
    --color-accent-own: white;
    --color-accent: white;
    color: #fff !important;
    opacity: 1;
    top: auto;
    bottom: 2rem !important;
    right: 1rem;
    left: auto;
    height: 1.125rem;
    padding: .7rem;
    background: rgba(0,0,0,.2);
    border-radius: 0.625rem;
    line-height:unset;
}

.media-inner .MessageMeta .message-time {
    margin-right: 0 !important;
}

/* SCROLL */

.scroll-chat::-webkit-scrollbar-track {
    background: transparent !important;
}

.scroll-chat::-webkit-scrollbar-thumb {
    background-color: #bfbfbf;
    border-radius: 10px;
    border: 10px solid transparent;
}

.scroll-chat::-webkit-scrollbar {
    width: 5px !important;
}

#text-message {
    transition: height 100ms ease;
    /*overflow: hidden;*/
    outline: 0;
    border: 0;
    height: auto;
    background-color: rgb(33,33,33) !important;
    color: #ffffff;
    white-space: break-spaces;
}

#text-message * {
    background-color: rgb(33,33,33) !important;
    color: #FFFFFF !important;
    text-decoration: none !important;
    border: 0 !important;
}

.ripple-wrapper {
    display: flex;
    flex-shrink: 1;
    margin-top: -1rem;
    margin-bottom: -0.25rem;
    padding: .5rem;
    padding-left: .375rem;
    border-radius: 0.375rem;
    overflow: hidden;
    cursor: pointer;
    align-items: center;
    z-index: 1000;
    user-select: none;
}

.ripple-indicator-wrapper {
    position: relative;
    margin: .125rem 0;
    width: .125rem;
    min-width: .125rem;
    overflow: hidden;
}

.ripple-indicator {
    background-color: rgba(131,120,219,0.501961);
    position: relative;
    will-change: transform;
    transition: transform .25s ease-in-out;
    clip-path: url("#clipPath1");
    width: 2px;
    height: 36px;
    transform: translateY(0px);
}

.clip-current {
    --height: 17px;
    --translate-y: 19px;
    --translate-track: 0px;
    height: 20px;
    position: absolute;
    left: 0;
    top: 0;
    width: .125rem;
    background: rgb(135,116,225);
    border-radius: .0625rem;
    will-change: transform;
    transition: transform .25s ease-in-out;
}

.ripple-container {
    overflow: hidden;
    margin-inline-start: .375rem;
    margin-top: .125rem;
    max-width: 15rem;
    min-width: 15rem;
    flex-grow: 1;
    transition: .25s ease-in-out transform;
}

.ripple-message-name {
    font-weight: 500;
    font-size: 13px;
    line-height: 1rem;
    height: 1rem;
    color: rgb(135,116,225);
    margin-bottom: .7rem;
    white-space: pre;
    text-align: initial;
}

.ripple-message-name > span {
    display: inline-flex;
    white-space: pre;
}

.ripple-message {
    margin-inline-start: .375rem;
    width: 100%;
    min-width: 0;
    height: 1.125rem;
    overflow: hidden;
    position: relative;
}

.ripple-message > div {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    animation-fill-mode: forwards !important;
    transition: background-color .2s;
}

.ripple-message-text {
    font-size: 13px;
    line-height: 1.125rem;
    height: 1.125rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0;
    color: #ffffff;
}

.editable-message-text {
    transition: color 50ms linear 0s !important;
    margin-bottom: 0;
    line-height: 1.3125;
    padding: calc((3.5rem - 16px * 1.3125) / 2) 0;
    white-space: pre-wrap;
    height: auto;
    overflow: hidden;
    unicode-bidi: plaintext;
    text-align: initial;
    background: rgba(0,0,0,0) !important;
    border: none !important;
    border-radius: 0 !important;
    box-shadow: none !important;
    font-size: 16px;
    display: block;
    width: 100%;
    word-break: break-word;
    -webkit-appearance: none;
    caret-color: #ffffff;
    outline: 0;
    border: 0;
}

#message-input-text:not(:only-child) .form-control {
    padding-left: .75rem;
}

.placeholder-text {
    top: 0;
    padding: 6px 12px;
    pointer-events: none;
    unicode-bidi: plaintext;
    position: absolute;
    bottom: auto;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
    color: #a2acb4;
    transition: opacity 200ms ease-out,transform 200ms ease-out;
}

.message-input-text .form-control.touched~.placeholder-text {
    opacity: 0;
    transform: translateX(1rem);
}

.message-subheader {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 0.25rem;
    padding: 0.125rem 0;
}

.message-subheader > .EmbeddedMessage {
    display: flex;
    align-items: center;
    font-size: calc(16px - .125rem);
    margin: 0 -0.25rem .0625rem;
    padding: .1875rem .25rem .1875rem .4375rem;
    border-radius: 0.375rem;
    position: relative;
    overflow: hidden;
    direction: ltr;
}

.EmbeddedMessage:before {
    content: "";
    display: block;
    position: absolute;
    top: 0.3125rem;
    bottom: 0.3125rem;
    left: 0.375rem;
    width: 2px;
    background: rgba(131,120,219,0.501961);
    border-radius: 2px;
}

.EmbeddedMessage > .message-text {
    overflow: hidden;
    margin-inline-start: 0.5rem;
    display: flex;
    flex-direction: column-reverse;
}

.message-text p {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    height: 1.125rem;
    margin-bottom: 0;
    flex: 1;
}

.message-text > .message-title {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: 0.125rem;
    flex: 1;
    display: block;
    font-size: calc(16px - .125rem);
    font-weight: 700;
    line-height: 1.25rem;
}

.video-note-wrapper {
    width: 240px;
    line-height: 1.2;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;
    /*overflow: hidden;*/
}

.video-note-wrapper .round-video {
    position: relative;
    width: 23rem;
    height: 23rem;
    cursor: pointer;
}

.video-wrapper {
    border: 1px solid #776d6d;
    position: absolute;
    left: 0;
    top: 0;
    border-radius: 50%;
    overflow: hidden;
}

.message-content-wrapper {
    opacity: 1;
    transform: scale(1) translateX(0);
    transition: transform 200ms ease-out;
}

.message-content .has-shadow {
    box-shadow: 0 1px 2px rgb(16,16,16,0.612);;
}

.message-content {
    border-radius: 0.375rem;
    position: relative;
    max-width: 29rem;
}