.animated {
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    z-index: 100;
}

@-webkit-keyframes fadeInDown {
    0% {
        opacity: 0;
        -webkit-transform: translateY(-20px);
        transform: translateY(-20px);
    }

    100% {
        opacity: 1;
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }
}

@keyframes fadeInDown {
    0% {
        opacity: 0;
        -webkit-transform: translateY(-20px);
        -ms-transform: translateY(-20px);
        transform: translateY(-20px);
    }

    100% {
        opacity: 1;
        -webkit-transform: translateY(0);
        -ms-transform: translateY(0);
        transform: translateY(0);
    }
}

@keyframes blinker {
    70% {
        border-color: #c50000;
    }
}

.fadeInDown {
    -webkit-animation-name: fadeInDown;
    animation-name: fadeInDown;
}

.navbar-default .nav > li > a {
    transition: border-color .3s;
}

.navbar-default .nav > li > a:hover {
    transition: border-color .5s;
}

.sponge-bob > div {
    border : 1px solid #ffffff;
    transition: border-color .5s;
}

.sponge-bob > div:hover {
    border-color: #E3E3E3FF !important;
    transition: border-color .5s;
}

.visit-stats > span {
    border : 1px solid #ffffff;
    transition: border-color .5s;
}

.visit-stats > span:hover {
    border : 1px solid #E3E3E3FF;
    transition: border-color .5s;
}

.dropdown-menu > li > a {
    transition: background-color .2s, color .2s;
}